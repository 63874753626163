<template>
  <div class="col-12" v-cloak @click="menu = false">
    <!-- overlay div -->
    <div class="team-overlay"></div>
    <!-- Alert to show user why his Workdiary tab is disable -->
    <div>
      <v-alert
        v-if="active_team_members_list.length <= 1 && !fromEmployeeSide"
        class="ma-0"
        border="left"
        colored-border
        color="#304FFE"
      >
        <v-icon color="#304FFE" size="40" class="mx-3"
          >mdi-account-question-outline</v-icon
        >
        <span class="mt-8"
          >Please invite your Team Members, you don't have any members yet.
</span
        >
        <div
          class="float-right white--text promt-btn align-center pointer"
          @click="routeToTeamMembers"
        >
          <div class="mt">Invite new members</div>
        </div>
      </v-alert>
      <v-alert
        v-if="active_team_members_list.length >= 2 && !fromEmployeeSide"
        class="ma-0"
        border="left"
        colored-border
        color="#304FFE"
      >
        <v-icon color="#304FFE" size="40" class="mx-3"
          >mdi-account-question-outline</v-icon
        >
        <span class="mt-8"
          >Sorry you don't have any stats yet, Please ask your team members to
          download the Desktop App.</span
        >
        <div
          class="float-right white--text promt-btn align-center pointer"
          @click="routeToDownloadApp"
        >
          <div class="mt">Download App</div>
        </div>
      </v-alert>
      <v-alert
        class="ma-0"
        border="left"
        colored-border
        color="#304FFE"
        v-if="fromEmployeeSide"
      >
        <v-icon color="#304FFE" size="40" class="mx-3"
          >mdi-account-question-outline</v-icon
        >
        <span class="mt-8 ml-10"
          >Sorry you don't have any stats yet, Please download the Desktop App &
          Start Working.</span
        >

        <div
          class="float-right white--text promt-btn align-center pointer"
          @click="routeToDownloadApp"
        >
          <div class="mt">Download App</div>
        </div>
      </v-alert>
    </div>
    <!-- Workdiary dummy page -->
    <div class="nill-opacity mt-5">
      <!-- Header -->
      <div class="d-flex flex-wrap align-items-center">
        <div class="ml-5 bold">
          <label class="mr-13 mb-2 mt-3">Date</label>
          <div class="dummy-input">
            Yesterday
            <v-icon class="float-right mr-2">mdi-calendar-range</v-icon>
          </div>
        </div>
        <div class="bold ml-5" v-if="!fromEmployeeSide">
          Team Members
          <div class="dummy-input">Select a Member</div>
        </div>
        <div
          class="search-btn mt-7 ml-16 d-flex align-content-center justify-content-center"
          color="#304FFE"
        >
          <div class="mt-1 ml-10">
            <v-icon color="grey" class="mt-n2 mr-2">mdi-find-replace</v-icon>
            Search
          </div>
        </div>
      </div>
      <!-- work done info of the day -->
      <div
        class="mt-10 work-info-day d-flex justify-space-between align-center"
      >
        <div class="ml-4">{{ dayWorkInfo.date }}</div>
        <div>Total Time Worked {{ dayWorkInfo.time }} Minutes</div>
        <div class="mr-5 play-btn"><div class="mt ml-4">Play Video</div></div>
      </div>
      <!-- work done info of the hour -->
      <div class="mt-3 work-info-hour">
        <div class="ml-3 mt-2">
          {{ hourWorkInfo.startTime }} to {{ hourWorkInfo.endTime }}
        </div>
        <div class="mt-1 ml-3 bold">
          Time Worked: {{ hourWorkInfo.totalTime }}
        </div>
      </div>
      <!-- Dummy screen shots -->
      <div class="ml-2 mt-3 d-flex flex-wrap">
        <div class="m-1" v-for="n in 20" :key="n.id">
          <div class="screenshots-data">
            <img
              class="dummy-screenshots"
              src="https://bt.stafftimerapp.com/img/no_image.24b57e28.svg"
              alt=""
            />
            <div class="mx-2 border-b">{{ timestamp }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  name: "DummyWorkDiary",
  props: ["fromEmployeeSide"],
  data() {
    return {
      dayWorkInfo: {
        date: "Wednesday 10th February 2021",
        time: "00:49",
      },
      hourWorkInfo: {
        startTime: "16:00",
        endTime: "17:00",
        totalTime: "16 mins",
      },
      timestamp: "4:03:56 PM",
    };
  },
  computed: {
    ...mapState("custom", ["active_team_members_list"]),
  },
  methods: {
    //   Route to team members
    routeToTeamMembers() {
      this.$router.push({
        name: "TeamMembers",
        query: {
          new: "yes",
        },
      });
    },
    routeToDownloadApp() {
      return this.$router.push({
        name: "DownloadApp",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.mt {
  margin-top: 5px !important;
}
.search-btn {
  background-color: #304ffe;
  width: 200px;
  height: 32px;
  border-radius: 5px;
  font-size: 18px;
  color: white;
}
.play-btn {
  background-color: #304ffe;
  width: 150px;
  height: 35px;
  border-radius: 5px;
  font-size: 18px;
  color: white;
}
.promt-btn {
  width: 175px !important;
  border-radius: 5px;
  height: 35px;
  text-align: center;
  vertical-align: center;
  background-color: #304ffe;
  z-index: 1 !important;
  animation-name: bounce-2 !important;
  animation-timing-function: ease !important;
  animation-duration: 2s !important;
  animation-iteration-count: infinite !important;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.team-overlay {
  position: fixed;
  z-index: 0.9;
  background: #000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.8;
}
.dummy-input {
  width: 250px;
  background-color: white;
  border: 1px solid rgb(202, 217, 223);
  margin-top: 5px;
  padding: 3px 0px 3px 5px;
  border-radius: 2px;
  font-weight: 300 !important;
}
.bold {
  font-weight: 600;
}
.work-info-day {
  height: 68px;
  background-color: #f1f6ff;
  border: 1px solid blue;
}
.work-info-hour {
  height: 68px;
  background-color: #f1f3f5;
  border: 1px solid rgb(216, 231, 231);
}
.dummy-screenshots {
  height: 80px;
  width: 115px;
  border: none !important;
}
.screenshots-data {
  height: 120px;
  width: 115px;
  background-color: #f1f6ff;
  border: 1px solid rgb(216, 231, 231);
}
.border-b {
  border-bottom: 7px solid white;
}
</style>
